/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"

const Information: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask
                id='mask0_63_2609'
                style={{
                    maskType: "alpha"
                }}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='24'
                height='24'
            >
                <rect width='24' height='24' fill='currentColor' />
            </mask>
            <g mask='url(#mask0_63_2609)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.9767 12.0234C22.9767 18.0757 18.029 23.0234 11.9767 23.0234C5.9244 23.0234 0.976685 18.0757 0.976686 12.0234C0.976686 5.97109 5.9244 1.02337 11.9767 1.02338C18.029 1.02338 22.9767 5.97109 22.9767 12.0234ZM11.9767 21.0234C16.9244 21.0234 20.9767 16.9711 20.9767 12.0234C20.9767 7.07566 16.9244 3.02338 11.9767 3.02338C7.02897 3.02338 2.97669 7.07566 2.97669 12.0234C2.97669 16.9711 7.02897 21.0234 11.9767 21.0234ZM11.9767 17.0234C11.4244 17.0234 10.9767 16.5757 10.9767 16.0234L10.9767 11.0234C10.9767 10.4711 11.4244 10.0234 11.9767 10.0234C12.529 10.0234 12.9767 10.4711 12.9767 11.0234L12.9767 16.0234C12.9767 16.5757 12.529 17.0234 11.9767 17.0234Z'
                    fill='currentColor'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12.9817 8.02338C12.9817 8.57566 12.534 9.02338 11.9817 9.02338L11.9727 9.02338C11.4204 9.02338 10.9727 8.57566 10.9727 8.02338C10.9727 7.47109 11.4204 7.02338 11.9727 7.02338L11.9817 7.02338C12.534 7.02338 12.9817 7.47109 12.9817 8.02338Z'
                    fill='currentColor'
                />
            </g>
        </svg>
    )
}
export default Information
